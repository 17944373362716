.contactUsWrapper {
  display: inline-block;
}

.contactUsContainer {
  position: relative;
  background: #fff;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: black;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  width: 85%;
}

.contactUsContainer .contactUsText {
  text-align: center;
  font-size: 41px;
  font-weight: 600;
}

.contactUsContainer form {
  padding: 30px 0 0 0;
}

.contactUsContainer form .formRow {
  display: flex;
  margin: 32px 0;
}

form .formRow .inputData {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}

.inputData input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}

.inputData label {
  position: sticky;
  float: left;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
}

.submitBtn {
  border: none;
}

.businessCard {
  z-index: 1001;
  width: 13%;
  padding: 20px;
  border: 2px solid #ccc;
  text-align: center;
  top: 23%;
  left: 2%;
  position: absolute;
}

.businessCard img {
  width: 100%;
  border-radius: 50%;
}

.businessCard a {
  text-decoration: none;
  margin-bottom: 10px;
  padding: 2px;
}

.businessCard a:hover {
  text-decoration: underline;
}

.facebookButton {
  position: absolute;
  margin-top: 25px;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: #1877f2;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  left: 30%;
}

.facebookButton:hover {
  background-color: #145686;
}

.facebookLogo {
  width: 20px;
  margin-right: 10px;
}

.socialsDiv {
  position: relative;
}

.bbbRating {
  position: absolute;
  margin-top: 10px;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  right: 20%;
}

.mbLogo {
  width: 55%;
  position: relative;
}

@media only screen and (min-width: 1950px) {
  .businessCard {
    left: 3%;
    width: 8%;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1800px) {
  .mbLogo {
    margin-left: 12%;
  }

  .businessCard {
    width: 14%;
    top: 35%;
    left: 1%;
  }

  .contactUsContainer {
    margin-left: 90px;
  }
}

@media only screen and (max-width: 600px) {

  .contactUsContainer {
    display: none;
  }

  .mbLogo {
    width: 100%;
  }

  .businessCard {
    position: relative;
    width: 60%;
    margin-top: 5px;
    left: 10%;
  }

  .contactUsWrapper {
    display: flex;
    margin-right: 30%;
    flex-direction: column;
  }

  .formRow {
    flex-direction: column;
  }

  .inputData {
    margin-bottom: 10px;
    padding: 20px 0 20px 0;

  }

  .input-data.textarea {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
  }

  .socialsDiv {
    position: relative;
  }

  .bbbRating {
    width: 5%;
    float: right;
    position: absolute;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    right: 28%;
  }

  .facebookButton {
    margin-top: 35px;
    width: 55%;
    position: absolute;
    background-color: #1877f2;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    left: 0%;
  }


}