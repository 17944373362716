@import "~react-image-gallery/styles/css/image-gallery.css";

html,
body {
  background-color: white;
  color: black;
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

.palominoLogo {
  width: 68%;
  height: auto;
}

.homeImageGalleryWrapper {
  display: inline-block;
  width: 60%;
}

.homeTabs {
  margin-right: 10px;
  position: fixed;
  top: 20%;
  right: 0;
}

.navTabs {
  margin-right: 10px;
  position: fixed;
  top: 20%;
  right: 0;
}

.navTabs a {
  text-decoration: none;
}

.whiteBGNav {
  margin-right: 10px;
  position: fixed;
  top: 20%;
  right: 0;
  background-color: white;
  border-radius: 15px;
}

.iconsWrapper figcaption {
  text-decoration: none;
}

.iconButtons {
  background: none;
  border: none;
}

.icons {
  padding: 5px;
  width: 30px;
  cursor: pointer;
}

figcaption {
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  color: #555;
  position: relative;
  top: -20px;
}

.finalCovenants,
.byLaws,
.platMap {
  padding: 50px;
}

.backroundImgDiv {
  background-image: url(./assets/mainPics/communityInfoBackground.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 25%;
}

.circle {
  position: absolute;
  top: 35%;
  left: 20px;
  width: 220px;
  height: 220px;
  border: 5px solid #08966e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 12px;
  font-size: xx-large;
  color: #333;
}

.mobile-circle {
  border: 2px solid #00B353;
  background-color: rgba(0, 179, 83, 0.1);
  margin-top: 84%;
  height: 100px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 10px 5px 5px gray;
  font-size: xx-large;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.communityInfoWrapper {
  margin-top: 4%;
  background-color: white;
  display: inline-block;
  padding: 5px;
  border: 2px solid;
  border-radius: 5px;
}

.communityInfoWrapperInner {
  position: relative;
  border: 2px solid;
  padding: 40px;
}

.communityInfoWrapper a {
  border: 2px solid #ccc;
  padding: 6px;
  text-decoration: none;
}

.communityInfoWrapper a:hover {
  text-decoration: underline;
}

.directionsWrapper {
  margin-top: 70px;
  display: inline-block;
  width: 70%;
  height: auto;
}

.mapDirectionsATag {
  padding: 5px 16px;
  display: inline-block;
  border-radius: 3px;
  font-size: x-large;
}

.firstTimeIncentive {
  width: 100%;
  height: 165px;
}

.facebookLogoNav {
  width: 35px;
  margin-bottom: 5px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
  touch-action: none;
}

.fullscreen img {
  max-width: 100%;
  max-height: 100%;
}

.carousel-wrapper {
  touch-action: none;
}

.carousel-wrapper img {
  cursor: pointer;
}

.fullscreen img:hover {
  transform: none;
  box-shadow: none;
}

.info-box {
  background-color: rgb(255, 255, 0);
  color: black;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin: auto;
  width: 20%;
}

.info-icon {
  margin-right: 8px;
  font-size: 16px;
}

.info-message {
  flex-grow: 1;
}

@media only screen and (min-width: 1024px) and (max-width: 1800px) {
  .backroundImgDiv {
    background-position: center;
  }

  .homeImageGalleryWrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 770px) {
  .info-box {
    width: 100%;
  }

  .backroundImgDiv {
    background-image: none;
  }

  .homeTabs {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 5px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }

  .homeDiv {
    padding-top: 72px;
  }

  .homeTabs a {
    margin: 0 5px;
    text-decoration: none;
  }

  .iconsWrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .iconButtons {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  .icons {
    width: 35px;
    padding: 0;
  }

  figcaption {
    font-size: 10px;
    margin-top: 18px;
    margin-bottom: 0;
    padding: 0;
  }

  .navTabs {
    display: inline-block;
    top: 13%;
    left: 76%;
    width: 25%;
  }

  .whiteBGNav {
    display: inline-block;
    top: 13%;
    left: 76%;
    width: 25%;
  }

  .palominoLogo {
    width: 100%;
    height: auto;
  }

  .homeImageGalleryWrapper {
    float: left;
    width: 100%;

    .image-gallery-left-nav,
    .image-gallery-right-nav {
      display: none;
    }
  }

  .directionsWrapper {
    float: left;
    margin-left: 5%;
  }

  .mapDirectionsATag {
    font-size: medium;
  }

  .communityInfoWrapper {
    width: 70%;
    float: left;
    font-size: small;
  }

  .communityInfoWrapperInner {
    padding: 0%;
  }
}