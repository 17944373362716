.floorPlanWrapper {
  --bg-color: #bd915b;
  --card-bg-color: #ffffff;
  --border-color: #333333;
  --text-padding: 15px;
  --border-radius: 8px;
  --card-width: 33.33%;
  --gap-size: 20px;
}

.floorPlansPage {
  background-color: #bd915b;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}


.floorPlanWrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 20px;
  background-color: var(--bg-color);
  gap: var(--gap-size);
  margin-top: 5%;
  margin-right: 10%;
}

.floorPlanCard {
  background-color: var(--card-bg-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: var(--text-padding);
  flex: 1 1 calc(var(--card-width) - 40px);
  max-width: calc(var(--card-width) - 40px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
}

.floorPlanCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.divider {
  border: 0;
  height: 2px;
  background-color: var(--border-color);
  margin: 15px 0;
  width: 100%;
}

@media (max-width: 1200px) {
  .floorPlanCard {
    flex: 1 1 calc(45% - 20px);
    max-width: calc(45% - 20px);
  }

  .floorPlanCard:hover {
    transform: none;
    box-shadow: none;
  }
}

@media (max-width: 768px) {
  .floorPlanWrapper {
    flex-direction: column;
    align-items: center;
    margin-right: 25%;
  }

  .floorPlanCard {
    flex: 1 1 90%;
    max-width: 90%;
  }

  .floorPlanCard:hover {
    transform: none;
    box-shadow: none;
  }
}

@media (max-width: 600px) {
  .floorPlanWrapper {
    flex-direction: column;
    align-items: stretch;
    margin-right: 20%;
  }

  .floorPlanCard {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: var(--gap-size);
  }

  .floorPlanCard:hover {
    transform: none;
    box-shadow: none;
  }
}